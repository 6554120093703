import { SortOrder } from 'antd/es/table/interface';
import { mainTagColor } from '../styles/styles-constants';
import { ContentStatus } from './statuses-types';
import { TranscodingJobState } from './transcoding-jobs-types';
import { AspectRatioTypes } from './videos-types';

export enum Order {
  'asc' = 'ASC',
  'desc' = 'DESC'
}

export enum ButtonType {
  default = 'default',
  primary = 'primary',
  ghost = 'ghost',
  dashed = 'dashed',
  link = 'link',
  text = 'text'
}

export enum SizeType {
  small = 'small',
  middle = 'middle',
  large = 'large'
}

export const COLUMN_ASCEND = 'ascend' as SortOrder;
export const COLUMN_DESCEND = 'descend' as SortOrder;

export enum Complexity {
  AllLevels = 'All Levels',
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced'
}

export enum Category {
  Barre = 'Barre',
  Pilates = 'Pilates',
  Recovery = 'Recovery',
  Specialty = 'Specialty',
  Strength = 'Strength',
  Yoga = 'Yoga',
  Mind = 'Mind',
  'How-To' = 'How-To',
  Golf = 'Golf'
}

export enum MovementFocus {
  AbsAndAss = 'Abs & Ass',
  Amplified = 'Amplified',
  ArmsAbs = 'Arms & Abs',
  ArmsShouldersAbs = 'Arms, Shoulders & Abs',
  Boxing = 'Boxing',
  Breathwork = 'Breathwork',
  Cardio = 'Cardio',
  ChestBackAbs = 'Chest, Back & Abs',
  ClassicBarre = 'Classic Barre',
  Core = 'Core',
  Express = 'Express',
  Family = 'Family',
  FullBody = 'Full Body',
  LowerBody = 'Lower Body',
  Meditation = 'Meditation',
  Mobility = 'Mobility',
  PowerYoga = 'Power Yoga',
  Release = 'Release',
  RestorativeYoga = 'Restorative Yoga',
  RotationalPower = 'Rotational Power',
  Run = 'Run',
  SportsSpecific = 'Sports-Specific',
  SoundBath = 'Sound Bath',
  TotalBody = 'Total Body',
  UpperBody = 'Upper Body',
  WarmUp = 'Warm-Up',
  YogaFlow = 'Yoga Flow',
  YogaSculpt = 'Yoga Sculpt',
  Demo = 'Demo'
}

export enum WorkoutEquipment {
  'Ankle Weights' = 'Ankle Weights',
  Barre = 'Barre',
  'Barre Ball' = 'Barre Ball',
  Bike = 'Bike',
  Dumbbells = 'Dumbbells',
  'Foam Roller' = 'Foam Roller',
  'Hypervolt Go' = 'Hypervolt Go',
  Mat = 'Mat',
  'Mini Bands' = 'Mini Bands',
  'Pilates Sliders' = 'Pilates Sliders',
  'Resistance Bands' = 'Resistance Bands',
  'Step Bench' = 'Step Bench',
  Treadmill = 'Treadmill',
  'Yoga Blocks' = 'Yoga Blocks',
  'Yoga Strap' = 'Yoga Strap'
}

export enum SessionType {
  VideoonDemand = 'Video on Demand',
  PlannedSession = 'Planned Session',
  LiftVOD = 'Lift VOD',
  LiftPlannedSession = 'Lift Planned Session',
  TpiVOD = 'TPI VOD',
  TpiPlannedSession = 'TPI Planned Session',
  Rest = 'Rest'
}

export enum SideUsageType {
  bilateral = 'bilateral',
  unilateral = 'unilateral'
}

export enum ExerciseComplexity {
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
  NA = 'N/A'
}

export enum ExerciseType {
  STUDIO = 'studio',
  LIFT = 'lift',
  TPI = 'tpi'
}

export enum Position {
  Standing = 'Standing',
  HalfKneeling = 'Half Kneeling',
  TallKneeling = 'Tall Kneeling',
  Seated = 'Seated',
  LyingDown = 'Lying Down',
  Quadruped = 'Quadruped'
}

export enum MovementPattern {
  HipHinge = 'Hip Hinge',
  FoamRoll = 'Foam Roll',
  Squat = 'Squat',
  Lunge = 'Lunge',
  OverheadPush = 'Overhead Push',
  OverheadPull = 'Overhead Pull',
  Push = 'Push',
  Pull = 'Pull',
  Carry = 'Carry',
  Core = 'Core',
  NA = 'N/A'
}

export enum PrimeMover {
  Pecs = 'Pecs',
  'Rectus Abdominis' = 'Rectus Abdominis',
  Obliques = 'Obliques',
  Deltoids = 'Deltoids',
  Triceps = 'Triceps',
  Quadriceps = 'Quadriceps',
  Calves = 'Calves',
  Adductors = 'Adductors',
  Abductors = 'Abductors',
  Traps = 'Traps',
  Glutes = 'Glutes',
  Hamstrings = 'Hamstrings',
  'Latissimus Dorsi' = 'Latissimus Dorsi',
  Rhomboids = 'Rhomboids',
  Biceps = 'Biceps'
}

export enum NumberArmsUsed {
  Zero = 0,
  One = 1,
  Two = 2
}

export enum MuscleGroup {
  Chest = 'Chest',
  Back = 'Back',
  Shoulders = 'Shoulders',
  Arms = 'Arms',
  ABSCore = 'Abs / Core',
  Glutes = 'Glutes',
  Legs = 'Legs',
  Calves = 'Calves'
}

export enum Orientation {
  '45 Deg Away to Studio' = '45 Deg Away to Studio',
  '45 Deg to Studio' = '45 Deg to Studio',
  'Back to Studio' = 'Back to Studio',
  'Facing Studio' = 'Facing Studio',
  'Side to Studio' = 'Side to Studio'
}

export enum PlaneOfMotion {
  'Frontal' = 'Frontal',
  'Sagittal' = 'Sagittal',
  'Transverse' = 'Transverse'
}

export enum ArmsHeights {
  'a' = 'a',
  'b' = 'b',
  'c' = 'c',
  'd' = 'd',
  'e' = 'e',
  'f' = 'f',
  'g' = 'g',
  'h' = 'h',
  'i' = 'i',
  'j' = 'j'
}

export enum CompletionType {
  'reps' = 'reps',
  'rest' = 'rest',
  'time' = 'time',
  'transition' = 'transition'
}

export enum ArmUsedEnum {
  none = 'none',
  left = 'left',
  right = 'right',
  both = 'both'
}

export const armUsedEnumDisplayMap = {
  [ArmUsedEnum.none]: 'None',
  [ArmUsedEnum.left]: 'Left',
  [ArmUsedEnum.right]: 'Right',
  [ArmUsedEnum.both]: 'Both'
};

export enum MovementSets {
  'a' = 1,
  'b' = 2,
  'c' = 3,
  'd' = 4,
  'e' = 5,
  'f' = 6,
  'g' = 7,
  'h' = 8,
  'i' = 9,
  'j' = 10
}

export const MovementHugs = {
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd'
};

export const movementHugsColorsMap = {
  [MovementHugs.a]: 'gold',
  [MovementHugs.b]: 'blue',
  [MovementHugs.c]: 'red',
  [MovementHugs.d]: 'magenta'
};

export const movementHugsHugAnglesMap = {
  [MovementHugs.a]: 0,
  [MovementHugs.b]: 20,
  [MovementHugs.c]: 35,
  [MovementHugs.d]: 50
};

export enum LimbUsedEnum {
  none = 'none',
  both = 'both',
  right_arm = 'right_arm',
  left_arm = 'left_arm',
  right_leg = 'right_leg',
  left_leg = 'left_leg',
  right_side = 'right_side',
  left_side = 'left_side'
}

export const limbUsedEnumDisplayMap = {
  [LimbUsedEnum.both]: 'Both',
  [LimbUsedEnum.right_arm]: 'Right arm',
  [LimbUsedEnum.left_arm]: 'Left arm',
  [LimbUsedEnum.right_leg]: 'Right leg',
  [LimbUsedEnum.left_leg]: 'Left leg',
  [LimbUsedEnum.right_side]: 'Right side',
  [LimbUsedEnum.left_side]: 'Left side',
  [LimbUsedEnum.none]: 'None'
};

export const bilateralLimbUsedEnumDisplayMap = {
  [LimbUsedEnum.both]: 'Both'
};

export const unilateralLimbUsedEnumDisplayMap = {
  [LimbUsedEnum.right_arm]: 'Right arm',
  [LimbUsedEnum.left_arm]: 'Left arm',
  [LimbUsedEnum.right_leg]: 'Right leg',
  [LimbUsedEnum.left_leg]: 'Left leg',
  [LimbUsedEnum.right_side]: 'Right side',
  [LimbUsedEnum.left_side]: 'Left side'
};

export enum ResourceAssignedTypes {
  all = 'all',
  notAssigned = 'notAssigned'
}

export const aspectRatioFilterColorsMap = {
  [AspectRatioTypes['9:16']]: 'purple',
  [AspectRatioTypes['16:9']]: 'green'
};
export const exerciseComplexityColors = {
  [ExerciseComplexity.Easy]: 'purple',
  [ExerciseComplexity.Medium]: 'green',
  [ExerciseComplexity.Hard]: 'red',
  [ExerciseComplexity.NA]: mainTagColor
};
export const primeMoverColors = {
  [PrimeMover.Pecs]: 'red',
  [PrimeMover['Rectus Abdominis']]: 'yellow',
  [PrimeMover.Obliques]: 'blue',
  [PrimeMover.Deltoids]: 'green',
  [PrimeMover.Triceps]: 'orange',
  [PrimeMover.Quadriceps]: 'brown',
  [PrimeMover.Calves]: 'purple',
  [PrimeMover.Adductors]: 'black',
  [PrimeMover.Abductors]: 'gray',
  [PrimeMover.Traps]: 'seagreen',
  [PrimeMover.Glutes]: mainTagColor,
  [PrimeMover.Hamstrings]: 'violet',
  [PrimeMover['Latissimus Dorsi']]: 'silver',
  [PrimeMover.Rhomboids]: 'bronze',
  [PrimeMover.Biceps]: 'gold',

  [ExerciseComplexity.Medium]: 'green',
  [ExerciseComplexity.Hard]: 'red',
  [ExerciseComplexity.NA]: mainTagColor
};
export const transcodingJobStateColors = {
  [TranscodingJobState.PENDING]: mainTagColor,
  [TranscodingJobState.RUNNING]: 'purple',
  [TranscodingJobState.SUCCEEDED]: 'green',
  [TranscodingJobState.FAILED]: 'red'
};
export const categoriesColors = {
  [Category.Barre]: 'red',
  [Category.Pilates]: 'yellow',
  [Category.Recovery]: 'blue',
  [Category.Specialty]: 'green',
  [Category.Strength]: 'orange',
  [Category.Yoga]: 'brown',
  [Category.Mind]: 'purple',
  [Category['How-To']]: '#eaff8f',
  [Category.Golf]: 'cyan'
};
export const movementSetsColors = {
  [MovementSets.a]: 'gold',
  [MovementSets.b]: 'blue',
  [MovementSets.c]: 'red',
  [MovementSets.d]: 'magenta',
  [MovementSets.e]: 'volcano',
  [MovementSets.f]: 'orange',
  [MovementSets.g]: 'purple',
  [MovementSets.h]: 'green',
  [MovementSets.i]: 'cyan',
  [MovementSets.j]: 'geekblue'
};
export const categoriesColorsMap = {
  [Category.Barre]: 'cyan',
  [Category.Pilates]: 'magenta',
  [Category.Recovery]: 'green',
  [Category.Specialty]: 'volcano',
  [Category.Strength]: 'gold',
  [Category.Yoga]: 'blue',
  [Category.Mind]: 'purple',
  [Category['How-To']]: '#eaff8f'
};
export const complexitiesColorsMap = {
  [Complexity.AllLevels]: 'default',
  [Complexity.Beginner]: 'green',
  [Complexity.Intermediate]: 'orange',
  [Complexity.Advanced]: 'red'
};
export const sessionTypesColorsMap = {
  [SessionType.VideoonDemand]: 'blue',
  [SessionType.LiftVOD]: 'purple',
  [SessionType.LiftPlannedSession]: 'fuchsia',
  [SessionType.PlannedSession]: 'cyan',
  [SessionType.TpiVOD]: 'yellow',
  [SessionType.TpiPlannedSession]: 'orange',
  [SessionType.Rest]: 'green'
  // [SessionType.PlannedSessionTemplate]: 'green'
  // [SessionType.FreeForme]: 'lime',
  // [SessionType.PersonalTraining]: 'orange'
};
export const statusesColorsMap = {
  [ContentStatus.created]: '#FFC53D',
  [ContentStatus.unpublished]: '#BFBFBF',
  [ContentStatus.published]: '#73D13D',
  [ContentStatus.archived]: '#FF4D4F'
};

export enum UserDevicePlatform {
  studio = 'studio',
  ios = 'ios',
  android = 'android',
  web = 'web',
  studio_lift = 'studio_lift'
}

export enum UserDeviceType {
  phone = 'phone',
  tablet = 'tablet',
  tv = 'tv',
  watch = 'watch'
}
